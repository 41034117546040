import React, { useEffect, useState } from 'react';
import './ResetPassword.scss';
import { Formik, Form, Field} from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { API_URL, API } from '../AppConstants.js';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useLocation
  } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        background: 'white',
        color: 'black',
        width: "100%",
        marginBottom: "1.25em",
        "& .MuiInputBase-input":{
            color: 'black'
        }
    }
})
const ResetPassword = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    let location = useLocation();
    let { key } = useParams();

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    <h1>Reiniciar contraseña</h1>
                    <Formik
                    initialValues={{
                        password: '',
                        repeatPassword:''
                    }}
                    validate={ values => {
                        const errors = {};
                        if(!values.password) {
                            errors.password = 'Requerido'
                        }
                        if(!values.repeatPassword) {
                            errors.repeatPassword = 'Requerido'
                        }
                        if(values.password && values.repeatPassword && values.password !== values.repeatPassword ){
                            errors.repeatPassword = 'Las claves deben coincidir'
                        }
                        return errors
                    }}
                    onSubmit={(values, {setSubmitting})=>{
                        let url = API_URL +  API.usuario.resetPassword;
                        const params = new URLSearchParams();
                        params.append('hash', key);
                        params.append('password', values.password);
                        return axios.post(url, 
                            {hash: key, password: values.password},{
                                headers:{
                                    'Content-Type':  'application/json'
                                }
                            }).then((response)=>{
                                alert("Cambio de clave exitoso")
                                setSubmitting(false)
                            }).catch((error)=>{
                                alert("Este link solo puede ser usado una vez, por favor intente hacer clic en olvido su clave desde la aplicación móvil y repetir el proceso")
                                setSubmitting(false)
                            });
                    }}
                    >

                    {({submitForm, isSubmitting, errors}) => (
                    <Form >
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="email">Nueva clave</label>
                            </div>
                            <div className="col-12">
                                <Field component={TextField} name="password" type="password"  id="password" className={classes.root} variant="outlined" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="email">Repetir nueva clave</label>
                            </div>
                            <div className="col-12">
                                <Field component={TextField} name="repeatPassword" type="password"  id="repeatPassword" className={classes.root} variant="outlined" />
                            </div>
                        </div>
                        <Button variant="contained" color="primary" onClick={submitForm}
                                disabled={isSubmitting}
                                  
                                >Guardar</Button>
                    </Form>)}

                    </Formik>
                    { loading && <div className="d-flex mt-2  justify-content-center"><CircularProgress /></div>}
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;