import './Contact.scss';
import supoprtImage from '../images/support.png';
const Contact = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1>Contacto</h1>
                    <div className="row">

                        <div className="col">
                            <img className="soporte" alt="Support" src={supoprtImage} />
                        </div>
                        <div className="col support-data">
                            <p>Si tiene problemas al utilizar nuestra plataforma puede escribirnos a <a
                                    href="mailto:soporte@judopd.com">soporte@judopd.com</a></p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <h2>Judopd</h2>
                    <p>Judopd es una plataforma deportiva que permite realizar competencias individuales de judo</p>
                    <p>Puedes registrarte desde nuestro sitio web o nuestra aplicación móvil y puedes retar a otros
                        contrincantes</p>
                    <p>La plataforma inicialmente estará disponible para deportistas de la ciudad de medellín</p>

                </div>
            </div>
        </div>
    );
}
export default Contact;