import './Categories.scss';
import React, { useEffect, useState } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CategoryService from '../services/category.service';

const useStyles = makeStyles({
    table:{
        
    },
    tableCell: {
        
    }
})

const Categories = () => {

    const [categories, setCategories ] = useState([]);
    const [loading, setLoading ] = useState(false);

    useEffect(()=>{
        setLoading(true);
        CategoryService.getCategories().then((response)=>{
            setCategories(response.categorias);
            setLoading(false);
        })
        
    }, []);
    const classes = useStyles();
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 ">
                    <h1>Categorias</h1>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead >
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        Id
                                    </TableCell >
                                    <TableCell className={classes.tableCell}>
                                        Nombre
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Sexo
                                    </TableCell>
                                    
                                </TableRow>
                            </TableHead>
                            {!loading && <TableBody>
                                { categories.map((row)=>(
                                    <TableRow key={row.categoriaId}>
                                        <TableCell className={classes.tableCell}>
                                            {row.categoriaId}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.nombre}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.sexoId===0?'Masculino':'Femenino'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>}
                        </Table>
                    </TableContainer>
                    {loading && <div className="d-flex mt-2 justify-content-center"><CircularProgress/> </div>}

                </div>
            </div>
        </div>
    )
}

export default Categories;