import authHeader from './auth-header'
import axios from 'axios';
import { API_URL, API }  from '../AppConstants'
import {USER_ROLES} from '../AppConstants';

const getUsers = () => {
    let url = `${API_URL}${API.usuario.getAll}`;
    url = url.replace('{page}', 1).replace('{limit}', 20)
    
    
    return axios.get(url, { headers: authHeader()}).then((response)=>{
        return response.data;
    });
}

const getUser = async ({ queryKey }) => {
    const [_, id] = queryKey
    let url = `${API_URL}${API.usuario.get}`;
    url = url.replace('{id}', id);
    

    const { data } = await axios.get(url, {headers: authHeader()})
    return data
}

const getSportsClubPlayers = (clubId) => {
    let url = `${API_URL}${API.usuario.getAll}`;
    url = url.replace('{page}', 1).replace('{limit}', 20)
    url = url + `&clubId=${clubId}`;
    
    return axios.get(url, { headers: authHeader()}).then((response)=>{
        return response.data;
    });
}

const removeSportClubFromUser = (userId) => {
    let url = `${API_URL}${API.usuario.removeSportsClub}`;
    url = url.replace('{userId}', userId);
    return axios.get(url, { headers: authHeader()}).then((response)=>{
        return response.data;
    });
}
const hasRole = (roleName) => {
    if(getUserLogged()==null || getUserLogged() === undefined)
        return false;
    return getUserLogged().roles.includes(roleName);
}

const isAdmin = () => {
    return hasRole(USER_ROLES.admin);
}
const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
}

const getUserLogged = () => {
    return JSON.parse(localStorage.getItem("user"));
}

const setToken = (token) => {
    localStorage.setItem("token", token);
}

const getToken = () => {
    return localStorage.getItem("token");
}

const removeUser = () => {
    return localStorage.removeItem('user');
}

const removeToken = () => {
    return localStorage.removeItem('token');
}

const enableDisableUser = (userId, newStatus) => { 
    let url = `${API_URL}${API.usuario.enableDisableUser}`;
    url = url.replace('{userId}', userId).replace('{newStatus}', newStatus);
    return axios.get(url, {headers: authHeader()}).then((response)=>{
        return response.data;
    })
}

const exported = {
    getUsers,
    setUser,
    getUserLogged,
    getUser,
    getToken,
    setToken,
    hasRole,
    removeUser,
    removeToken,
    getSportsClubPlayers,
    removeSportClubFromUser,
    isAdmin,
    enableDisableUser
}


export default exported