import './AccessDenied.scss';

const AccessDenied = () => {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    <h1>Acceso denegado</h1>
                    <p>Usted no tiene acceso a la página que intenta acceder</p>
                </div>
            </div>
        </div>

        
        
    );
}
export default  AccessDenied;