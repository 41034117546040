
import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import axios from 'axios';
import { ERROR_MESSAGES } from './AppConstants';
import authService  from './services/auth.service';

// Add a 401 response interceptor
axios.interceptors.response.use( (response) => {
  return response;
},  (error) => {
  if (401 === error.response.status) {
    if(error.response.data.message===ERROR_MESSAGES.expiredToken){
      authService.logout();
      window.location = '/user/login';
    } else {
      window.location = '/access-denied';
    }
    return Promise.reject(error);
  } else {
      return Promise.reject(error);
  }
});

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main:'#079339' ,
      contrastText: '#fff',
      text: '#000'
    },
    secondary: {
      main:'#EDB63F' ,
      contrastText: '#fff',
      text: '#000'
    }
  }
})
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
