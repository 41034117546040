import React from "react";
import "./PrivacyPolicy.scss";
const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 ">
          <h1>Términos y Condiciones de la Aplicación Móvil</h1>
          <div class="agreement-container">
            <p dir="ltr"></p>
            <p dir="ltr">
              Este documento establece las condiciones mediante las cuales se
              regirá el uso de la aplicación móvil: Judopp, en adelante
              “Judopp”, la cual es operada por JUDO PIRAMIDAL DINÁMICO en
              adelante “JPD”, compañía constituida en Colombia y domiciliada en
              la ciudad de Medellín.
            </p>
            <p dir="ltr">
              JPD se dedica a la programación y realización de torneos
              presenciales, inicialmente de Judo, en los cuales el usuario en
              adelante el “Competidor”, podrá inscribirse sin costo para
              competir por una bolsa de dinero.
            </p>
            <p dir="ltr">
              Hallará unas definiciones que podrán facilitarle la compresión y
              justificación de los contenidos de este documento.
            </p>
            <p dir="ltr">
              Recomendamos dar lectura juiciosa a los Términos y Condiciones de
              Judopp y si acepta los Términos y Condiciones, proceda a aportar
              los datos solicitados para darle su trámite y definir el sexo y la
              categoría de peso.
            </p>
            <p dir="ltr">
              Los Términos y Condiciones pueden ser modificados en cualquier
              momento, y cada una de las modificaciones, será efectiva después
              de su publicación en nuestra Página Web, por tanto, invitamos a
              que revise y entienda en su totalidad los Términos y Condiciones.
              Para el caso de no estar de acuerdo, le sugerimos abstenerse de
              acceder a nuestro contenido y de navegar en la Judopp.
            </p>
            <p dir="ltr">
              El Competidor se compromete a leer los Términos y Condiciones aquí
              establecidos, antes de efectuar su inscripción, en caso de
              realizar la inscripción, se entiende que cuenta con el
              conocimiento integral de este documento y la consecuente
              aceptación de la totalidad de sus condiciones.
            </p>
            <p dir="ltr">
              El Competidor reconoce que el ingreso de su información personal,
              y los datos que contiene la aplicación a su disposición, los
              realiza de manera voluntaria y que cumple con las leyes
              colombianas. En caso de que se acceda por parte de menores de
              edad, éstos deben contar con la supervisión de un adulto en todo
              momento desde la descarga y durante el uso de la aplicación, sino
              se cumpla esta condición, le agradecemos no hacer uso de la
              Judopp.
            </p>
            <br />
            <br />
            <p dir="ltr">DEFINICIONES</p>
            <br />
            <p dir="ltr">
              Ciudad: requerido para ubicar el lugar en donde habita el
              Competidor, con el fin de poder definir los sitios de competencia.
            </p>
            <p dir="ltr">
              Competidor: Que compite con otros que aspiran a un mismo objetivo,
              en este caso en los torneos que programa y realiza JPD.
            </p>
            <p dir="ltr">
              Contenido: son todos los textos, imágenes, videos y productos que
              JPD publica en la Judopp.
            </p>
            <p dir="ltr">
              Derechos Intelectuales: son aquellos que se confieren a las
              personas sobre las creaciones de su mente.
            </p>
            <p dir="ltr">
              Correo electrónico: medio por el cual JPD y el Competidor tendrán
              comunicación directa referente a temas concernientes a los torneos
              que JPD programe y realice.
            </p>
            <p dir="ltr">
              Club deportivo de Judo: asociación con su propia personalidad
              jurídica, conformada por personas jurídicas o físicas. Este tiene
              como objetivo la promoción y la práctica del Judo.
            </p>
            <p dir="ltr">
              Fecha de nacimiento: esta nos garantiza saber de la mayoría de
              edad del Competidor.
            </p>
            <p dir="ltr">
              Franja: es el nombre dado a los 6 niveles de crecimiento que tiene
              el sistema llamado Judo Piramidal Dinámico. Inicia con la primera
              franja: amarillo, la segunda franja: naranja, la tercera franja:
              verde, la cuarta franja: azul, la quinta franja: marrón y la sexta
              franja: negro, de donde sale el ganador.
            </p>
            <p dir="ltr">
              Grado de cinturón: El Judo tiene 6 colores: amarillo, naranja,
              verde, azul, marrón y negro, este último tiene niveles de 1° Dan,
              2° Dan, hasta el 10° Dan.
            </p>
            <p dir="ltr">
              IP: Es una representación numérica del punto de internet donde
              está conectado un dispositivo.
            </p>
            <p dir="ltr">JPD: Judo Piramidal Dinámico.</p>
            <p dir="ltr">
              Judo: arte marcial japonés. Deporte del ciclo olímpico.
            </p>
            <p dir="ltr">
              Judopp: aplicación móvil App para inscribirse a los torneos que
              JPD programe y realice.
            </p>
            <p dir="ltr">
              Nombre y apellidos: permiten identificar al Competidor que se
              inscribe para participar en los torneos JPD.
            </p>
            <p dir="ltr">
              Peso: permite conocer, en qué peso se encuentra el competidor,
              para definir en cual división de peso podrá participar.
            </p>
            <p dir="ltr">
              Seguro de salud (EPS): documento indispensable para poder
              compartir, garantizando la atención en caso de lesiones u otras
              situaciones que correspondan a la salud física y mental del
              Competidor.
            </p>
            <p dir="ltr">
              Sexo: necesario para definir la categoría en que podrá participar,
              sea de hombres o mujeres.
            </p>
            <p dir="ltr">
              Teléfono: medio de comunicación inmediata que permitirá informar y
              solicitar información el Competidor.
            </p>
            <p dir="ltr">
              Tipo de documento: indispensable para constatar varios datos del
              Competidor que desea participar en los torneos JPD y poderle dar
              la transparencia a los torneos JPD.
            </p>
            <p dir="ltr">
              Torneos JPD: son competencias de Judo en las categorías clásicas,
              tanto en masculino como en femenino.
            </p>
            <br />
            <br />
            <p dir="ltr">DE LOS TORNEOS JPD</p>
            <br />
            <p dir="ltr">Argumentación</p>
            <br />
            <p dir="ltr">
              El Judo es un deporte aficionado, esto implica competir por el
              honor de representar a un país y su federación, o a un
              departamento o región y su liga, o a un club de un municipio
              afiliado a una liga.
            </p>
            <p dir="ltr">
              La participación y éxito no otorga una bolsa de dinero o premio
              equivalente, sino medallas, trofeos, diplomas, reconocimiento
              público, viáticos y premios de poco valor económico, dado el
              limitado ingreso de las federaciones, las ligas y sus clubes.
            </p>
            <p dir="ltr">
              Si bien es cierto, que obtener estos logros es altamente
              gratificante y que en casos pudiera ser suficiente, también sería
              válido, tanto para los satisfechos y no, a tener una recompensa
              económica por:
            </p>
            <p dir="ltr">- la inversión de su tiempo,</p>
            <p dir="ltr">- hacer su propio patrocinio,</p>
            <p dir="ltr">
              - exponer su salud por los riesgos que se propician en los
              entrenamientos y en la competencia,
            </p>
            <p dir="ltr">- por sus privaciones sociales,</p>
            <p dir="ltr">
              - a veces por privilegiar el deporte en detrimento del estudio.
            </p>
            <p dir="ltr">
              Todos esos esfuerzos, dedicación y disciplina, fueron necesarios
              para el éxito al momento de la participación.
            </p>
            <p dir="ltr">
              Entonces, por lo anterior, debería ser legítimo y legal, como lo
              es en otros deportes de combate, individuales y colectivos,
              lucrarse del conocimiento adquirido, siempre y cuando éste, esté
              reglado y normado. De paso, aportaría a la creación de empleos
              directos: los competidores, árbitros y mesa directiva y todos
              aquellos que hacen posible el desarrollo del evento, e indirectos:
              hotelería para participantes en el evento y acompañantes,
              restaurantes, cafeterías y la oferta informal.
            </p>
            <p dir="ltr">
              Esto atraería a los participantes de los torneos federados, porque
              además de foguearse, recibirían dinero; también a quienes les
              gusta la competencia y/o aspiran a ser campeones y que han quedado
              en el camino, a que puedan tener revancha deportiva con utilidad
              económica y permitiría que otros pudieran vivir de este bello
              arte. Esto impulsará y aumentará la competitividad y masificación
              del Judo como disciplina y como labor.
            </p>
            <p dir="ltr">
              Pudiera entonces el Judo evolucionar y no quedarse en ser un
              deporte aficionado y una filosofía de vida, válidas y útiles para
              el buen vivir, sino también, una manera de subsistencia legítima,
              que permitiera paliar ante las exigencias de ingreso, necesario
              para aportar a la calidad de vida.
            </p>
            <p dir="ltr">
              Con el sueño y propósito de muchos, de satisfacer a quienes
              disfrutan de la permanente competencia normada, nace Judo
              Piramidal Dinámico JPD, quien diseña, implementa y desarrolla un
              sistema de participación en competencias de Judo, en el cual los
              premios son económicos.
            </p>
            <p dir="ltr">
              Este sistema se acoge en gran parte a la reglamentación del Judo
              olímpico. Las categorías y divisiones de peso son las siguientes:
            </p>
            <p dir="ltr">
              Categoría femenina: hasta 52 kilos. De más de 52 y hasta 63 kilos.
              Y más de 63 kilos.
            </p>
            <p dir="ltr">
              Categorías masculinas: hasta 66 kilos. De más de 66 kilos y hasta
              81 kilos. Y más de 81 kilos.
            </p>
            <br />
            <br />
            <p dir="ltr">ALCANCE Y USO</p>
            <br />
            <p dir="ltr">
              En la Judopp, se pondrá a disposición del Competidor, la
              información necesaria que debe diligenciar para inscribirse y
              poder participar en los torneos que realiza JPD, su completa
              información es indispensable para poder participar en los torneos
              JPD, por lo tanto, el Competidor, se hace responsable de la
              veracidad, autenticidad y vigencia de la información suministrada,
              sin embargo, JPD, exigirá en los torneos, los documentos físicos
              que acrediten la veracidad de la información, para garantizar la
              transparencia en los torneos JPD.
            </p>
            <p dir="ltr">
              JPD podrá adicionar, modificar o eliminar las funcionalidades en
              cualquier momento, lo cual acepta el Competidor mediante la
              instalación de la aplicación. JPD podrá realizar modificaciones,
              las cuales serán notificadas al Competidor a través de la Judopp.
            </p>
            <br />
            <br />
            <p dir="ltr">REQUISITOS PARA USO</p>
            <br />
            <p dir="ltr">
              El Competidor deberá contar con un dispositivo móvil inteligente
              (Smartphone) o Tableta con sistema operativo Android o IOS y con
              acceso a internet.
            </p>
            <p dir="ltr">
              Para acceder al portal, el Competidor solicitará a JPD un link,
              que conduce a la Judopp, allí se le otorgará un Usuario y una
              Clave, Clave que deberá personalizar al terminar de hacer la
              debida inscripción. Adicional a lo anterior, se requerirá que el
              Competidor registre unas preguntas de seguridad, las cuales serán
              solicitadas al momento de querer ingresar al portal a través de un
              equipo no registrado.
            </p>
            <br />
            <br />
            <p dir="ltr">OBLIGACIONES DE LOS USUARIOS</p>
            <br />
            <p dir="ltr">
              El Competidor se obliga a usar la Judopp y los contenidos
              encontrados en ella de una manera diligente, correcta y lícita y a
              abstenerse de las conductas siguientes:
            </p>
            <p dir="ltr">
              -Utilizar los contenidos de forma, con fines o efectos contrarios
              a la ley, a la moral y a las buenas costumbres.
            </p>
            <p dir="ltr">
              -Reproducir, copiar, representar, utilizar, distribuir,
              transformar o modificar los contenidos de la aplicación o permitir
              el acceso del público a través de cualquier modalidad de
              comunicación pública.
            </p>
            <p dir="ltr">
              -Utilizar los contenidos de cualquier manera que entrañen un
              riesgo de daño o inutilización de la aplicación o de los
              contenidos o de terceros.
            </p>
            <p dir="ltr">
              -Suprimir, eludir o manipular el derecho de autor y demás datos
              identificativos de los derechos de autor incorporados a los
              contenidos y/o a los dispositivos técnicos de protección.
            </p>
            <p dir="ltr">
              -Emplear los contenidos de la información obtenida a través de la
              Judopp para distribuir, transmitir, remitir, modificar, rehusar o
              reportar la publicidad o los contenidos con fines comerciales.
            </p>
            <p dir="ltr">
              -No permitir que terceros usen la aplicación móvil con su clave.
            </p>
            <p dir="ltr">
              -Utilizar la Judopp y los contenidos con fines lícitos y/o
              ilícitos, contrarios a lo establecido en estos Términos y
              Condiciones, o lesivos de los derechos e intereses de terceros, o
              que puedan dañar, inutilizar, sobrecargar o deteriorar la
              aplicación y los contenidos o que impidan la normal utilización
              y/o disfrute de esta y de sus contenidos.
            </p>
            <br />
            <br />
            <p dir="ltr">PROPIEDAD INTELECTUAL</p>
            <br />
            <p dir="ltr">
              Todo el material informático, gráfico, publicitario, fotográfico,
              de multimedia, audiovisual y de diseño, así como todos los
              contenidos, textos y bases de datos puestos a su disposición en
              esta aplicación, están protegidos por derechos de autor y/o
              propiedad industrial cuyo titular es PJD, y en algunos casos de
              terceros que han autorizado su uso o explotación. Todos los
              contenidos en la aplicación están protegidos por las normas sobre
              derecho de autor y por todas las normas nacionales e
              internacionales que le sean aplicables.
            </p>
            <p dir="ltr">
              Queda prohibido todo acto de copia, reproducción, modificación,
              creación de trabajos derivados, venta o distribución, exhibición
              de los contenidos de esta aplicación, de manera o por medio
              alguno.
            </p>
            <p dir="ltr">
              Cualquier uso no autorizado de los contenidos constituirá una
              violación del presente documento y a las normas vigentes sobre
              derechos de autor, a las normas vigentes nacionales e
              internacionales sobre Propiedad Industrial, y a cualquier otra que
              sea aplicable.
            </p>
            <br />
            <br />
            <p dir="ltr">FORMALIDADES REGLAMENTARIAS</p>
            <br />
            <p dir="ltr">
              Para competir el Judoka debe cumplir con las siguientes
              Formalidades Reglamentarias:
            </p>
            <p dir="ltr">
              -Tener o ser mayor de 18 años, para su verificación se exige la
              presentación de un documento de identificación sea la cédula o el
              pasaporte.
            </p>
            <p dir="ltr">
              -Pertenecer a un club deportivo de Judo, con el fin de garantizar
              que el Competidor hace parte de una escuela que forma en el arte
              deportivo del Judo.
            </p>
            <p dir="ltr">-Ser como mínimo cinturón azul.</p>
            <p dir="ltr">
              -Presentar afiliación digital o física a una EPS y si tiene
              convenios con otras entidades que atienden urgencias médicas,
              informarlas y anexarlas.
            </p>
            <p dir="ltr">
              -Presentar un examen médico que indique que está apto para las
              exigencias físicas y psicológicas que implican la competencia;
              esto con el fin de proteger la integridad física y mental de los
              Competidores.
            </p>
            <p dir="ltr">
              -Para poder competir, el Competidor firmará un documento, en donde
              exonera a JPD de cualquier tipo de responsabilidad derivada de la
              competencia.
            </p>
            <p dir="ltr">
              -Cumplir con el pesaje en la división de peso en la cual va a
              competir, lo cual debe hacerse de manera presencial, para
              garantizar la transparencia en la competición.
            </p>
            <p dir="ltr">
              -Los Competidores inscritos y participantes en los torneos
              programados por JPD, estarán sujetos a las reglas y condiciones
              que se establezca en cada oportunidad por parte de JPD.
            </p>
            <p dir="ltr">
              Y otras optativas y de índole disciplinario que encontrará en el
              reglamento interno de los torneos JPD, o que serán informadas
              oportunamente.
            </p>
            <br />
            <br />
            <p dir="ltr">USO DE INFORMACIÓN Y PRIVACIDAD</p>
            <br />
            <p dir="ltr">
              Consentimiento de Autorización de Tratamiento de Datos Personales
              y Autorización de Imágenes y Videos que da el Competidor a JPD
            </p>
            <br />
            <p dir="ltr">
              De conformidad con la Ley 1581 de 2012 (Ley de Protección de Datos
              Personales) y el Decreto 1377 de 2013 (Decreto que reglamenta la
              Ley 1581 de 2012), los Competidores, autorizan de manera previa,
              expresa e informada a JPD, para que realice cualquier operación
              sobre sus datos personales, que incluye: recolección, acceso,
              almacenamiento, utilización, entrega, transmisión, transferencia
              y/o supresión de sus datos personales conforme a la Política de
              Tratamiento de la Información de JPD.
            </p>
            <p dir="ltr">
              La aceptación de estos Términos y Condiciones, implica también, la
              aprobación del Consentimiento de Autorización de Tratamiento de
              Datos Personales y Autorización de Imágenes y Videos que da el
              Competidor a JPD:
            </p>
            <br />
            <p dir="ltr">
              Dando cumplimiento a lo dispuesto en la Ley 1581 de 2012 “por el
              cual se dictan disposiciones generales para la protección de datos
              personales” y de conformidad al Decreto 1377 de 2013, con la firma
              de este documento manifiesto que he sido informado por JPD de lo
              siguiente:
            </p>
            <p dir="ltr">
              -JPD actuará como responsable del Tratamiento mis Datos Personales
              de los cuales soy titular y que, conjunta o separadamente podrá
              recolectar, usar y tratar mis datos personales conforme la
              política de Tratamiento de Datos que aparece en la Judopp.
            </p>
            <p dir="ltr">
              -Que me ha sido informada que la finalidad de la recolección de
              mis datos personales, será para la promoción de productos y
              servicios de JPD.
            </p>
            <p dir="ltr">
              -Mis derechos como titular de los datos son los previstos en la
              Constitución Colombiana y en la Ley, especialmente el derecho a
              conocer, actualizar, rectificar y suprimir información personal,
              así como el derecho a revocar el consentimiento acordado, otorgado
              para el tratamiento de datos personales.
            </p>
            <p dir="ltr">
              -Los derechos pueden ser ejercidos a través de los canales
              dispuestos por JPD y observando la política de Tratamiento de
              Datos Personales de JPD.
            </p>
            <p dir="ltr">
              - JPD, garantizará la confidencialidad, libertad seguridad,
              veracidad, transparencia, acceso y circulación restringida de mis
              datos y se reservará el derecho a modificar su política de
              Tratamiento de Datos Personales en cualquier momento. Cualquier
              cambio será informado y publicado oportunamente en la Página Web.
            </p>
            <p dir="ltr">
              -Mediante el presente formato autorizo a JPD para que haga el uso
              y tratamiento de mis derechos de imagen para incluirlos sobre
              fotografías y producciones audiovisuales (videos); así como de los
              Derechos de Autor; los Derechos Conexos y en general todos
              aquellos derechos de propiedad intelectual que tengan que ver con
              el derecho de imagen.
            </p>
            <p dir="ltr">
              -Esta autorización se regirá por las normas legales aplicables y
              en particular por las siguientes:
            </p>
            <ol>
              <li dir="ltr">
                <p dir="ltr">
                  Este video/foto podrá ser utilizado con fines deportivos,
                  comerciales, educativos e informativos en diferentes
                  escenarios y plataformas de JPD.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Este video/foto será utilizado para promover los torneos JPD y
                  para la venta de productos derivados de la actividad de JPD.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  La presente autorización no tiene ámbito geográfico
                  determinado, por lo que las imágenes en las que aparezca
                  podrán ser utilizadas en el territorio del mundo, así mismo,
                  tampoco tiene ningún límite de tiempo para su concesión, ni
                  para explotación de las imágenes, o parte de estas, por lo que
                  mi autorización se considera concedida por un plazo de tiempo
                  ilimitado.
                </p>
              </li>
            </ol>
            <p dir="ltr">
              La información obtenida para el Tratamiento de mis Datos
              Personales y la Autorización de Uso de Imágenes y Videos la he
              suministrado en forma voluntaria y es verídica.
            </p>
            <p dir="ltr">
              Para este fin, los Competidores en los torneos JPD, autorizan a
              JPD para publicar, en los medios utilizados por JPD, como redes
              sociales, sus registros audiovisuales, con el fin de informar al
              público de la realización y resultado de los torneos JPD.
            </p>
            <p dir="ltr">
              JPD, no se hace responsable por el buen comportamiento de los
              espectadores, ni por cualquier tipo de daño moral, físico,
              material, ni cualquier otra índole que pudiera involucrarse como
              relacionado con los torneos programados por JPD.
            </p>
            <p dir="ltr">
              JPD podrá en cualquier momento antes de la entrega de los premios,
              verificar el cumplimiento de los términos y condiciones de los
              Competidores y excluir a quienes no las cumplan dejando constancia
              de ello y se reserva la facultad de disponer del dinero del
              Competidor de la categoría que se declara desierta.
            </p>
            <p dir="ltr">
              Los Competidores, ceden o transfieren a JPD de manera irrevocable,
              a perpetuidad y para el territorio del mundo, todo y cualquier
              derecho que recaiga o pueda recaer sobre su imagen y voz, fijadas
              o grabadas con ocasión de la participación en cualquiera de las
              redes sociales.
            </p>
            <br />
            <br />
            <p dir="ltr">JURISDICCIÓN</p>
            <br />
            <p dir="ltr">
              Estos términos y condiciones y todo lo que tenga que ver con esta
              aplicación, se rige por las leyes de colombianas.
            </p>
            <br />
            <br />
            <p dir="ltr">USO DE DIRECCIONES IP</p>
            <br />
            <p dir="ltr">
              Una dirección de Protocolo de Internet (IP) es un conjunto de
              números que se asigna automáticamente a su o dispositivo móvil
              cuando usted accede a su proveedor de servicios de internet, o a
              través de la red de área local (LAN) de su organización o la red
              de área amplia (WAN). Los servidores web automáticamente
              identifican su dispositivo móvil por la dirección IP asignada a él
              durante su sesión en línea.
            </p>
            <p dir="ltr">
              JPD podrá recolectar direcciones IP para propósitos de
              administración de sistemas y para auditar el uso de nuestro sitio,
              todo lo anterior de acuerdo con la autorización de protección de
              datos que se suscribe para tal efecto. Normalmente no vinculamos
              la dirección IP de un usuario con la información personal de ese
              usuario, lo que significa que cada sesión de usuario se registra,
              pero el usuario sigue siendo anónimo para nosotros. Sin embargo,
              podemos usar las direcciones IP para identificar a los usuarios de
              nuestro sitio cuando sea necesario con el objeto de para exigir el
              cumplimiento de los términos de uso del sitio, o para proteger
              nuestro servicio, sitio u otros usuarios.
            </p>
            <br />
            <br />
            <p dir="ltr">SEGURIDAD</p>
            <br />
            <p dir="ltr">
              JPD está comprometido en la protección de la seguridad de su
              información personal. JPD tiene implementados mecanismos de
              seguridad que aseguran la protección de la información personal,
              así como los accesos únicamente al personal y sistemas
              autorizados, también contra la pérdida, uso indebido y alteración
              de sus datos de usuario bajo nuestro control.
            </p>
            <br />
            <p dir="ltr">
              Declaro haber leído y aceptado en su totalidad, todos los Términos
              y Condiciones establecidos por la Judopp.
            </p>
            <div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
