import React from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import MatchService from '../../services/match.service'
import styles from  '../../GlobalStyles.module.css'
import { Button, CircularProgress, Snackbar } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import moment from 'moment';
export const EditMatch = (): JSX.Element =>{
    const {id} = useParams<{id: string }>(),
    { isLoading, isError, data: match } = useQuery(["match", id], MatchService.getMatch)
    const [open, setOpen] = React.useState(false);
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    return (
        <div className="container">
        <div className="row justify-content-center">
            {!isLoading?<div className="col-12">
                <h1>Combate {id} </h1>
                <div>
                    <span className={styles.highLighted}>Retador</span> {`${match.combatienteRetador.nombre} ${match.combatienteRetador.apellidos}`}
                </div>
                <div>
                    <span className={styles.highLighted}>Retado</span> {`${match.combatienteRetado.nombre} ${match.combatienteRetado.apellidos}`}
                </div>
                <Formik
                    initialValues={{fecha:null}}
                    validate={values =>{
                        const errors:{fecha?: string , hora?: string } = {};
                        if(!values.fecha){
                            errors.fecha = "Requerido"
                        }
                        return errors
                    }}
                    onSubmit={(values, {setSubmitting})=>{
                        
                        if(values && values.fecha){
                            console.log('submiting')
                            setSubmitting(true);
                            let newFecha = moment(values.fecha).format('YYYY-MM-DD HH:mm:ss')
                            console.log('newFecha', newFecha)
                            MatchService.schedule(newFecha, id).then((response)=>{
                                setOpen(true)
                                setSubmitting(false);
                            }).catch(error=>{
                                console.log('error', error)
                                setSubmitting(false);
                            })

                        }
                    }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            submitForm
                        })=>(
                            <Form >
                                <div >
                                    <KeyboardDateTimePicker
                                        clearable
                                        variant="inline"
                                        format="DD/MM/yyyy HH:mm"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Fecha de realización"
                                        value={values.fecha}
                                        onChange={newDate => {
                                            setFieldValue("fecha", newDate)
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        />

                                <span className={styles.error}>{errors.fecha && touched.fecha && errors.fecha}</span>
                                </div>

                                <Button variant="contained" color="primary"  onClick={submitForm} >Ingresar</Button>
                            </Form>
                        )}
                    </Formik>
            </div>:<CircularProgress />}
        </div>
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message='Programado exitosamente'
        
        />
        </div>  
    )
}