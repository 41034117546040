import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, SET_MESSAGE } from './types';
import AuthService from "../services/auth.service";
import jwt_decode from "jwt-decode";

export const login = (username, password) => (dispatch ) =>{
    return AuthService.login(username, password).then(
        (data)=>{
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { 
                    user: jwt_decode(data.token)
                }
            })
            return Promise.resolve();
        },
        (error) => {
            // TODO change by the error of the backend
            const message = "login failed";

            dispatch({
                type: LOGIN_FAIL
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message
            });

            return Promise.reject()
        }
    )
}

export const logout = () => (dispatch) => {
    AuthService.logout();

    dispatch({
        type: LOGOUT
    })
}