import './SportsClubsPlayers.scss';
import { Breadcrumbs, Button, ButtonGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import  UserService from '../services/user.service';
import ClubService from '../services/sport-clubs.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';


import {
    Link,
    useParams
  } from "react-router-dom";

export const SportsClubsPlayers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sportsClub, setSportClub] = useState({});
    let { id } = useParams();


    useEffect(()=>{
            setLoading(true);
            ClubService.getSportsClub(id).then((response)=>{
                setSportClub(response);
                UserService.getSportsClubPlayers(id).then((response) =>{
                    setUsers(response.usuarios);
                    setLoading(false);
                });
            });
    }, [])

    const useStyles = makeStyles({
        table:{
            
        },
        tableCell: {
            
        }
    })
    const removeSportClub = (userId) => {
        UserService.removeSportClubFromUser(userId).then((response)=>{
            setLoading(loading + 1);
            UserService.getSportsClubPlayers(id).then((response) =>{
                setUsers(response.usuarios);
                setLoading(loading - 1);
            });
        });
    }
    const classes = useStyles();
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    {!loading &&
                        <h1>{sportsClub.nombre}</h1>}
                    {loading &&
                        <h1>Club deportivo</h1>}
                    <Breadcrumbs aria-label="breadcrumbs">
                        <Link color="inherit" to="/">
                            Inicio
                        </Link>
                        <Link color="inherit" to="/sports-club">
                            Clubes deportivos
                        </Link>
                        <Link color="inherit" to={'/sports-club/'+id}>
                            {sportsClub.nombre}
                        </Link>

                        <Typography color="textPrimary">
                            Deportistas
                        </Typography>

                    </Breadcrumbs>
                    {users.length===0 && !loading && <>
                        <ul>
                            <li>El club deportivo aún no tiene deportistas asociados</li>
                            <li>Para asociar deportistas los usuarios deben seleccionar 
                                en su club en el perfil accediendo desde la aplicación móvil</li>
                        </ul>
                    </>}
                    { !loading && users.length>0 &&  <> 
                     <TableContainer>
                        <Table className={classes.table}>
                            <TableHead >
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        Id
                                    </TableCell >
                                    <TableCell className={classes.tableCell}>
                                        Nombre
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Apellidos
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Email
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Documento de identidad
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                        Acciones
                                    </TableCell>
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { users.map((row, index)=>(
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableCell}>
                                            {row.usuarioId}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.nombre}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.apellidos}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.email}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.documentoDeIdentidad}
                                        </TableCell>
                                        <TableCell>
                                            <Button 
                                                color="secondary" 
                                                variant="outlined" 
                                                onClick={(e)=>{removeSportClub(row.usuarioId)}}
                                                startIcon={<DeleteIcon />}>Quitar del club</Button>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer></>}
                    { loading && <CircularProgress />}
                </div>
            </div>
        </div>
    )
}