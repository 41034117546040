import { API_URL, API } from '../AppConstants';
import axios from 'axios';
import authHeader from './auth-header';

const getMatches = () => {
    let url = `${API_URL}${API.matches.getAll}`;

    return axios.get(url, {headers: authHeader()}).then(response=>{
        return response.data;
    });
}
const getMatch = async ({ queryKey }) => {
    const [_, id] = queryKey
    let url = `${API_URL}${API.matches.get}`.replace('{id}', id);

    const {data} = await axios.get(url, {headers: authHeader()});
    return data
}
const schedule = (fecha, matchId) => {
    let url = API_URL +  API.matches.schedule.replace('{id}', matchId);
    return axios.post(url, 
        {
            fecha
        },
        {headers: authHeader()}
        ).then((response)=>{     
            return response.data;
        });
};
const exported = {
    getMatches,
    getMatch,
    schedule
};

export default  exported;