import { Breadcrumbs, Button, ButtonGroup, Typography } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import './SportsClubUsers.scss';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import  UserService from '../services/user.service';
import { makeStyles } from '@material-ui/core/styles';
import ClubService from '../services/sport-clubs.service';
import PersonAdd from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';

const SportsClubUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sportsClub, setSportClub] = useState({});
    let { id } = useParams();


    useEffect(()=>{
        setLoading(true);

        ClubService.getSportsClub(id).then((response) =>{
            setSportClub(response);
            UserService.getUsers().then((response) =>{
                setUsers(response.usuarios);
                setLoading(false);
            })
        });
    }, [])

    const removeSportClub = (userId) => {
        UserService.removeSportClubFromUser(userId).then((response)=>{
            setLoading(loading + 1);
            UserService.getSportsClubPlayers(id).then((response) =>{
                setUsers(response.usuarios);
                setLoading(loading - 1);
            });
        });
    }

    const useStyles = makeStyles({
        table:{
            
        },
        tableCell: {
            
        }
    })
    const classes = useStyles();
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    {!loading &&
                        <h1>{sportsClub.nombre}</h1>}
                    {loading &&
                        <h1>Club deportivo</h1>}
                    <Breadcrumbs aria-label="breadcrumbs">
                        <Link color="inherit" to="/">
                            Inicio
                        </Link>
                        <Link color="inherit" to="/sports-club">
                            Clubes deportivos
                        </Link>
                        <Link color="inherit" to={'/sports-club/'+id}>
                            {sportsClub.nombre}
                        </Link>

                        <Typography color="textPrimary">
                            Usuarios
                        </Typography>

                    </Breadcrumbs>
                    { !loading && <>
                        <div className="float-right">
                            <ButtonGroup variant="contained" color="primary" aria-label="Acciones">
                                <Button 
                                    component={Link} 
                                    to={`/sports-club/${sportsClub.clubId}/add-user`}
                                    startIcon={<PersonAdd/>}
                                    >Agregar Usuario</Button>
                            </ButtonGroup>
                        </div>

                        
                     <TableContainer>
                        <Table className={classes.table}>
                            <TableHead >
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        Id
                                    </TableCell >
                                    <TableCell className={classes.tableCell}>
                                        Nombre
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Apellidos
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Email
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Documento de identidad
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Acciones
                                    </TableCell> 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { users.map((row, index)=>(
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableCell}>
                                            {row.usuarioId}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.nombre}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.apellidos}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.email}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {row.documentoDeIdentidad}
                                        </TableCell>
                                        <TableCell>
                                            <Button 
                                                color="secondary" 
                                                variant="outlined" 
                                                onClick={(e)=>{removeSportClub(row.usuarioId)}}
                                                startIcon={<DeleteIcon />}>Quitar del club</Button>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </>}
                    { loading && <CircularProgress />}
                </div>
            </div>
        </div>
    )
}

export default SportsClubUsers;